<template>
  <div class="container">
    <el-container style="height: 100vh">
      <el-aside width="208px">
        <el-menu
          text-color="#fff"
          @open="handleOpen"
          :default-active="navLeftActive"
          router
          @select="handleSelect"
          active-text-color="#DE561D"
          @close="handleClose"
        >
          <div v-for="item in $store.state.asideList" :key="item.id">
            <el-menu-item
              v-show="item.sonList.length == 0"
              :index="item.component"
            >
              <i :class="item.icon"></i>
              {{ item.menuName }}
            </el-menu-item>
            <el-submenu v-show="item.sonList.length != 0" :index="item.id">
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.menuName }}</span>
              </template>
              <el-menu-item
                style="padding-left: 50px"
                v-for="des in item.sonList"
                :key="des.id"
                :index="des.component"
                >{{ des.menuName }}</el-menu-item
              >
            </el-submenu>
          </div>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              v-for="item in $store.state.menuLists"
              :key="item.path"
              :to="{ path: item.path }"
              replace
            >
              <i :class="item.icon"></i>
              <span class="menu_name">{{ item.name }}</span></el-breadcrumb-item
            >
          </el-breadcrumb>
          <div>
            <el-dropdown trigger="click">
              <div>
                <i class="el-icon-user"></i>
                <span>
                  {{ userinfo.username }}
                </span>
                <i class="el-icon-caret-bottom"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="userinfo.accountType == 'COMPANY'">
                  <el-button
                    type="text"
                    style="color: #000"
                    @click="goUserInfo()"
                  >
                    基础信息
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    type="text"
                    style="color: #000"
                    @click="
                      () => {
                        resetShow = true;
                      }
                    "
                  >
                    修改密码
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    type="text"
                    style="color: #000"
                    @click="loginOut()"
                  >
                    退出登录
                  </el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
    <div>
      <el-dialog title="修改密码" :visible.sync="resetShow" width="30%" center>
        <span style="font-weight: 700"> 当前登录账号-{{ userinfo.phone }}</span>
        <el-form
          ref="pwdForm"
          :model="pwdForm"
          label-width="100px"
          :rules="pwdRules"
          style="margin-top: 30px"
        >
          <el-form-item label="输入验证码" prop="code">
            <el-input
              class="input_login code"
              prefix-icon="el-icon-message"
              v-model="pwdForm.code"
              placeholder="请输入验证码"
            >
              <template slot="append">
                <el-button
                  type="text"
                  @click="getCode()"
                  :disabled="isCountingDown"
                >
                  {{
                    isCountingDown ? `${countdown}s 后重新发送` : "获取验证码"
                  }}
                </el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="输入新密码" prop="password">
            <el-input
              prefix-icon="el-icon-lock"
              class="input_login"
              :type="passwordVisible ? 'text' : 'password'"
              v-model="pwdForm.password"
              placeholder="请输入密码"
            >
              <template slot="append">
                <i
                  v-if="passwordVisible"
                  class="el-icon-view"
                  style="font-size: 18px"
                  @click="togglePasswordVisibility()"
                ></i>
                <img
                  v-if="!passwordVisible"
                  @click="togglePasswordVisibility()"
                  src="../assets/brows_off@2x.png"
                  style="width: 18px"
                  alt=""
                />
              </template>
            </el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="resetShow = false">取 消</el-button>
          <el-button type="primary" @click="resetPwd('pwdForm')"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getMenuList, sendSms } from "@/api/comminapi";
export default {
  computed: {
    navLeftActive: function () {
      const { meta, path } = this.$route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    pathUrl: function () {
      if (this.currentRoute.name) {
        return "/" + this.$route.name.split("_")[0] + "/";
      }
    },
  },
  data() {
    return {
      menuList: [],
      activeIndex: "",
      userinfo: {},
      resetShow: false,
      pwdForm: {},
      passwordVisible: false,
      isCountingDown: false,
      countdown: 60, // 倒计时时间
      isCountingDown: false, // 是否在倒计时
      countdownTimer: null, // 用于存储计时器的引用
      pwdRules: {
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [
          { required: true, message: "请输入新的密码", trigger: "blur" },
          { min: 6, message: "密码长度不低于6位数", trigger: "blur" },
          {
            pattern: /^\w+$/,
            message: "请输入字母/数字/下划线",
            trigger: "blur",
          },
        ],
      },
    };
  },
  activated() {
    this.userinfo = JSON.parse(localStorage.getItem("userInfo"));
    this.init();
  },
  mounted() {
    this.userinfo = JSON.parse(localStorage.getItem("userInfo"));
    this.init();
  },
  watch: {
    $router() {
      this.handleSelect(this.activeIndex);
    },
  },
  methods: {
    goUserInfo() {
      this.$router.push({ path: "/personal" });
    },
    resetPwd(formName) {
      this.pwdForm.username = this.userinfo.phone;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          resetPwd(this.pwdForm).then((res) => {
            this.$store.commit("clearUserinfo");
            this.$store.commit("clearToken");
            this.$router.push({ path: "/login" });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getCode() {
      this.startCountdown();
      sendSms({ phone: this.userinfo.phone }).then((res) => {});
    },
    loginOut() {
      this.$store.commit("clearUserinfo");
      this.$store.commit("clearToken");
      this.$router.push({ path: "/login" });
    },
    init() {
      if (this.userinfo) {
        getMenuList({ adminId: this.userinfo.id }).then((res) => {
          this.menuList = res;
          this.$store.commit("setAside", res);
          this.menuList.forEach((item) => {
            if (item.component == this.$route.path) {
              this.$store.commit("clearMenu");
              this.$store.commit("addMenu", {
                path: item.component,
                name: item.menuName,
              });
            } else {
              item.sonList.forEach((it) => {
                if (it.component == this.$route.path) {
                  this.$store.commit("clearMenu");
                  this.$store.commit("setMenu", [
                    { path: item.component, name: item.menuName },
                    { path: it.component, name: it.menuName },
                  ]);
                }
              });
            }
          });
        });
      }
    },
    handleSelect(key, path) {
      // console.log(path, "handleSelect", this.menuList);
      this.$store.commit("clearMenu");
      this.menuList.forEach((item) => {
        if (item.id == path[0]) {
          this.$store.commit("addMenu", {
            path: item.component,
            name: item.menuName,
          });
          item.sonList.forEach((it) => {
            if (it.component == path[1]) {
              this.$store.commit("addMenu", {
                path: it.component,
                name: it.menuName,
              });
            }
          });
        }
      });
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    startCountdown() {
      this.isCountingDown = true;
      this.countdown = 60; // 设置倒计时时长

      this.countdownTimer = setInterval(() => {
        this.countdown--;

        // 倒计时结束
        if (this.countdown <= 0) {
          clearInterval(this.countdownTimer); // 清除定时器
          this.isCountingDown = false; // 恢复按钮状态
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
:deep .el-menu {
  background-color: transparent !important;
  border: none;
  i {
    color: #fff;
  }
}
.menu_name {
  font-weight: 400;
}
:deep .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  .menu_name {
    font-weight: 400;
  }
}
:deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  .menu_name {
    font-weight: 700;
  }
}
:deep .el-submenu__title {
  padding-left: 0px;
}
:deep .el-submenu__title:hover {
  color: #de561d !important;
  i {
    color: #de561d;
  }
}
:deep .el-submenu__title:hover {
  color: #de561d !important;
  i {
    color: #de561d;
  }
}
:deep .el-menu-item:hover {
  background-color: #fff !important;
  color: #de561d !important;
  i {
    color: #de561d;
  }
}
:deep .el-menu-item.is-active {
  background-color: #fff !important;
  color: #de561d !important;
  i {
    color: #de561d;
  }
}
.el-aside {
  background: url(../assets/meun/Group2572.png) no-repeat center;
}
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-header {
  padding-left: 26px;
  padding-right: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
}
.el-main {
  background: #ffffff;
  margin: 24px;
}
</style>