import http from "@/http/require";

//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */

// export function test(params) {
//   return http.get(`${resquest}/getList.json`, params);
// }

// 获取用户手册
export function getAppText(data) {
    return http.get(`/text/app/detail`, data)
}

// 机构禁用接口
export function updateCompanyStatus(data) {
    return http.put(`/company`, data)
}

// 管理员禁用接口
export function getUserBaseInfo(data) {
    return http.get(`/company/baseInfo`, data)
}

// 管理员禁用接口
export function statusAdmin(data) {
    return http.put(`/admin/disable`, data)
}

// 查询单条数据详情
export function getOneDetail(data) {
    return http.get(`/data/detail/${data.dataId}`, data)
}

// 分页某个用户某一天的检测数据
export function getDayPage(data) {
    return http.get(`/data/day/page`, data)
}

// 查看用户头像基础信息
export function getAvaDetail(data) {
    return http.get(`/user/baseInfo/${data.id}`, data)
}

// 查看用户检测基础信息
export function getDetail(data) {
    return http.get(`/daily/detail/${data.id}`, data)
}

// 管理端账号验证码登陆
export function codeLogin(data) {
    return http.post(`/admin/login/code`, data)
}

// 获取验证码
export function sendSms(data) {
    return http.get(`/sms/send`, data)
}

// 管理端修改密码
export function resetPwd(data) {
    return http.post(`/admin/login/resetPassword`, data)
}

// 管理端账号密码登陆
export function adminLogin(data) {
    return http.post(`/admin/login`, data)
}

// 获取当前账户菜单列表
export function getMenuList(data) {
    return http.get(`/adminMenu/admin/bind`, data)
}

// 新增操作记录
export function recordOperate(data) {
    return http.postRec(`/adminOperate`, data)
}