import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import moment from 'moment';
import 'element-ui/lib/theme-chalk/index.css';
import{recordOperate} from '@/api/comminapi'
Vue.config.productionTip = false
Vue.prototype.$service = function (data) {
  let opeList = [
    {id:'1',ope:'新增数据'},
    {id:'2',ope:'编辑数据'},
    {id:'3',ope:'删除数据'},
    {id:'4',ope:'登录账号'},
  ]
  opeList.forEach(item => {
    if(item.id == data.operate){
      data.operate = item.ope
    }
  })
  // console.log(data)
  recordOperate(data).then(res => {
    
  })
}
Vue.use(ElementUI);
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
  }else{
    return '--'
  }
});
Vue.filter('formatDateh', function(value) {
  if (value) {
    return moment(String(value)).format('HH');
  }else{
    return '--'
  }
});
Vue.filter('formatDatehm', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm');
  }else{
    return '--'
  }
});
Vue.filter('formatDateHH', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD');
  }
});

Vue.filter('formatDatemm', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm:ss');
  }
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


