import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuLists:[
      {path:'/',name:'首页'}
    ],
    asideList:[],
    token:null,
  },
  getters: {
    
    
  },
  mutations: {
    setToken(state,data){
      localStorage.setItem('token', data);
    },
    getToken(state,data){
      return localStorage.getItem('token');
    },
    setUserinfo(state,data){
      localStorage.setItem('userInfo', JSON.stringify(data));
    },
    clearUserinfo(state,data){
      localStorage.setItem('userInfo', '');
      return state.userInfo = null
    },
    clearToken(state){
      localStorage.setItem('token', '');
      return state.token = null
    },
    setAside(state,data){
      return state.asideList = data
    },
    addMenu(state,data){
      return state.menuLists.push(data)
    },
    setMenu(state,data){
      return state.menuLists = data
    },
    clearMenu(state){
      return state.menuLists = []
    },
    delMenu(state){
      console.log(state.menuLists,'state.menuLists')
      return state.menuLists.pop()

    }
  },
  actions: {
  },
  modules: {
  }
})
