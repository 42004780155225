import axios from "axios";
import store from "../store/index";
import router from '../router';
import {
  Message
} from 'element-ui';
console.log(process.env.NODE_ENV)
let baseURL
// http://192.168.3.138:9005
if (process.env.NODE_ENV == 'development') {
  baseURL = 'http://39.107.192.59:9005';
} else if (process.env.NODE_ENV == 'debug') {
  baseURL = 'http://39.107.192.59:9005';
} else if (process.env.NODE_ENV == 'production') {
  // baseURL = 'http://39.107.192.59:9005';
  baseURL = 'http://183.134.89.172:9005';
}
const service = axios.create({
  baseURL,
  timeout: 120000,
});
service.interceptors.request.use(
  (config) => {
    //console.log("拦截了");
    // 如果有token 就携带tokon
    let token = localStorage.getItem('token')
    if (token) {
      //console.log(token, "token");
      config.headers['x-token'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
// 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.code == 0) {
      // console.log(res.data.data, url)
      if (response.config.headers['x-token']) {
        localStorage.setItem("token", response.config.headers['x-token']);
      }
      return response;
    } else if (response.data.code == 403) {
      // 当返回的code为200时跳转到登录页面
      localStorage.setItem("token", '');
      router.push({
        path: '/login'
      });
      Message({
        message: response.data.msg || '请重新登录',
        type: 'warning',
      });
      return Promise.resolve(null); // 返回空的Promise对象，防止继续处理
    } else {
      return response;
    }
  },
  (error) => {
    console.log(error + "err1111");
    return Promise.reject(error);
  }
);
export default {
  get(url, params) {
    return new Promise((resolve, reject) => {
      service
        .get(url, {
          params: params,
        })
        .then((res) => {
          if (res.data.code == 0) {
            resolve(res.data.data);
            if (typeof res.data.data === 'boolean' || res.data.data == 'success') {
              Message({
                message: res.data.msg,
                type: "success",
              });
            }
          }
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  postDownlod(url, params) {
    return new Promise((resolve, reject) => {
      service
        .post(url, params, {
          responseType: 'blob'
        })
        .then((res) => {
          const fileDownload = require("js-file-download");
          // let framName = fileDownload(res.data, decodeURIComponent(res.headers.get('content-disposition').split("=")[1]));
          // console.log(framName)
          const url = window.URL.createObjectURL(new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          })); // 创建下载链接
          const link = document.createElement("a");
          link.href = url;
          link.download = `${(new Date()).getTime()}operate_data.xlsx`
          document.body.appendChild(link);
          link.click(); // 触发下载
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url)
          if (res.data.code == 0) {
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  post(url, params) {
    return new Promise((resolve, reject) => {
      service
        .post(url, params)
        .then((res) => {
          if (res.data.code == 0) {
            if (typeof res.data.data === 'boolean' || res.data.data == 'success') {
              resolve(res.data.msg);
              Message({
                message: res.data.msg,
                type: "success",
              });
            } else {
              resolve(res.data.data);
            }
          } else {
            Message({
              message: res.data.msg,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  postRec(url, params) {
    return new Promise((resolve, reject) => {
      service
        .post(url, params)
        .then((res) => {
          if (res.data.code == 0) {
            resolve(res.data.data);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  put(url, params) {
    return new Promise((resolve, reject) => {
      service
        .put(url, params)
        .then((res) => {
          if (res.data.code == 0) {
            resolve(res.data.data);
            if (typeof res.data.data === 'boolean' || res.data.data == 'success') {
              Message({
                message: res.data.msg,
                type: "success",
              });
            }
          }
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  delete(url, params) {
    return new Promise((resolve, reject) => {
      service
        .delete(url, {
          params
        })
        .then((res) => {
          if (res.data.code == 0) {
            resolve(res.data.data);
            if (typeof res.data.data === 'boolean' || res.data.data == 'success') {
              Message({
                message: res.data.msg,
                type: "success",
              });
            }
          } else {
            Message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  baseURL,
};