import Vue from 'vue'
import VueRouter from 'vue-router'
import containerView from '../views/containerView.vue'
import store from '../store'
Vue.use(VueRouter)


const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      activeMenuZh: '登录模块'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/loginView.vue')
  },
  {
    path: '/drawecg',
    name: 'drawecg',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/common/drawecg.vue')
  },
  {
    path: '/userAgreement',
    name: 'userAgreement',
    meta: {
      activeMenuZh: '用户手册'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/common/UserAgreement.vue')
  },
  {
    path: '/',
    component: containerView,
    children: [{
        path: '/',
        name: 'index',
        meta: {
          activeMenuZh: '首页'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/indexView.vue')
      },
      {
        path: '/personal',
        name: 'personal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/personalView.vue')
      },
      {
        path: '/nullPage',
        name: 'nullPage',
        meta: {
          activeMenuZh: '侧边栏'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/indexView.vue')
      },
      {
        path: '/user',
        name: 'user',
        meta: {
          activeMenuZh: '用户管理-用户列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/user/userListView.vue')
      },
      {
        path: '/user/more',
        name: 'lookMore',
        meta: {
          activeMenuZh: '用户管理-用户列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/user/lookMoreView.vue')
      },
      {
        path: '/user/lowrisk',
        name: 'lowRisk',
        meta: {
          activeMenuZh: '用户管理-低风险人群'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/user/lowRiskView.vue')
      },
      {
        path: '/user/hignrisk',
        name: 'hignRisk',
        meta: {
          activeMenuZh: '用户管理-高风险人群'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/user/hignRiskView.vue')
      },
      {
        path: '/user/detail',
        name: 'userDetail',
        meta: {
          activeMenu: '/user',
          activeMenuZh: '用户管理-用户列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/user/userDetailView.vue')
      },
      {
        path: '/dete',
        name: 'dete',
        meta: {
          activeMenuZh: '检测管理-检测记录列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/detection/detectionRecordView.vue')
      },
      {
        path: '/common/detection',
        name: 'detection',
        meta: {
          activeMenu: '/dete',
          activeMenuZh: '检测管理-检测记录列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/common/detectionDetail.vue')
      },
      {
        path: '/disposition',
        name: 'disposition',
        meta: {
          activeMenuZh: '配置管理-功能配置'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/disposition/featureView.vue')
      },
      {
        path: '/agreement',
        name: 'agreement',
        meta: {
          activeMenuZh: '配置管理-协议配置'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/disposition/agreementView.vue')
      },
      {
        path: '/agreement/operate',
        name: 'agreementOperate',
        meta: {
          activeMenu: '/agreement',
          activeMenuZh: '配置管理-协议配置'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/disposition/agreeOperateView.vue')
      },
      {
        path: '/institu',
        name: 'institu',
        meta: {
          activeMenuZh: '机构列表-合作机构列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/institu/coopeInstituView.vue')
      },
      {
        path: '/insCheck',
        name: 'insCheck',
        meta: {
          activeMenuZh: '机构列表-关联待审核列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/institu/insCheckView.vue')
      },
      {
        path: '/insChecked',
        name: 'insChecked',
        meta: {
          activeMenuZh: '机构列表-关联已审核列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/institu/insCheckedView.vue')
      },
      {
        path: '/institu/operate',
        name: 'instituOperate',
        meta: {
          activeMenu: '/institu',
          activeMenuZh: '机构列表-合作机构列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/institu/insOperateView.vue')
      },
      {
        path: '/organ',
        name: 'organ',
        meta: {
          activeMenuZh: '组织管理-组织模板'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/organ/organView.vue')
      },
      {
        path: '/organ/operate',
        name: 'organOperate',
        meta: {
          activeMenu: '/organ',
          activeMenuZh: '组织管理-组织模板'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/organ/organOperateView.vue')
      },
      {
        path: '/organ/list',
        name: 'organList',
        meta: {
          activeMenuZh: '组织管理-组织列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/organ/organListView.vue')
      },
      {
        path: '/organ/list/operate',
        name: 'organListOperate',
        meta: {
          activeMenu: '/organ/list',
          activeMenuZh: '组织管理-组织列表-查看组织成员'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/organ/organListOperateView.vue')
      },
      {
        path: '/organ/list/member',
        name: 'organMember',
        meta: {
          activeMenu: '/organ/list',
          activeMenuZh: '组织管理-组织列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/organ/organMember.vue')
      },
      {
        path: '/roles',
        name: 'roles',
        meta: {
          activeMenuZh: '角色管理-角色列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/roles/rolesListView.vue'),
      },
      {
        path: '/roles/operate',
        name: 'rolesOperate',
        meta: {
          activeMenu: '/roles',
          activeMenuZh: '角色管理-角色列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/roles/rolesOperateView.vue'),
      },
      {
        path: '/rolesId',
        name: 'rolesId',
        meta: {
          activeMenuZh: '角色管理-账号列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/roles/rolesIdView.vue'),
      },
      {
        path: '/rolesId/operate',
        name: 'rolesIdOpe',
        meta: {
          activeMenu: '/rolesId',
          activeMenuZh: '角色管理-账号列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/roles/rolesIdOpeView.vue'),
      },
      {
        path: '/roles/opeRecord',
        name: 'rolesORecord',
        meta: {
          activeMenuZh: '角色管理-操作记录'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/roles/opeRecordView.vue'),
      },
      {
        path: '/menu',
        name: 'menu',
        meta: {
          activeMenuZh: '菜单配置-菜单管理'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/menu/menuView.vue'),
      },
      {
        path: '/menu/operate',
        name: 'menuOperate',
        meta: {
          activeMenu: '/menu',
          activeMenuZh: '菜单配置-菜单管理'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/menu/menuOperateView.vue')
      },
      {
        path: '/menu/search',
        name: 'menuSearch',
        meta: {
          activeMenuZh: '菜单配置-搜索框配置'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/menu/menuSearchView.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router